import { MutableRefObject, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
    BarcodeCaptureListener,
    BarcodeCapture,
    barcodeCaptureLoader,
    BarcodeCaptureOverlay,
    BarcodeCaptureSettings,
    Symbology,
    BarcodeCaptureSession,
} from 'scandit-web-datacapture-barcode';
import {
    Camera,
    CameraSwitchControl,
    configure,
    DataCaptureContext,
    DataCaptureView,
    FrameSourceState,
    RectangularViewfinder,
    RectangularViewfinderLineStyle,
    RectangularViewfinderStyle,
} from 'scandit-web-datacapture-core';

export interface SDK {
    initialize: () => Promise<void>;
    cleanup: () => Promise<void>;
    connectToElement: (element: HTMLElement) => void;
    detachFromElement: () => void;
    enableCamera: (enabled: boolean) => Promise<void>;
    enableScanning: (enabled: boolean) => Promise<void>;
    addBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
    removeBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
}

export interface SDKWithLoadingStatus {
    loading: boolean;
    loaded: boolean;
    sdk: SDK;
}

export function createSDKFacade(): SDK {
    let context: DataCaptureContext | undefined;
    let view: DataCaptureView;
    let settings: BarcodeCaptureSettings;
    let barcodeCapture: BarcodeCapture;
    let overlay: BarcodeCaptureOverlay;
    let host: HTMLElement | undefined;
    let camera: Camera;
    let barcodeCaptureListener: BarcodeCaptureListener;

    function createHostElementIfNeeded(): HTMLElement {
        if (!host) {
            host = document.createElement('div');
            host.style.display = 'none';
            host.style.width = '100%';
            host.style.height = '100%';
            document.body.append(host);
        }
        return host;
    }

    return {
        async initialize() {
            // Enter your Scandit License key here.
            // Your Scandit License key is available via your Scandit SDK web account.
            // The library location option represents the location of the wasm file, which will be fetched asynchronously.
            await configure({
                libraryLocation: 'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
                licenseKey: import.meta.env.VITE_SCANDIT_LICENSEKEY,
                moduleLoaders: [barcodeCaptureLoader()],
            });
            context = await DataCaptureContext.create();
            settings = new BarcodeCaptureSettings();
            settings.enableSymbologies([Symbology.EAN8, Symbology.EAN13UPCA, Symbology.Code39]);
            settings.codeDuplicateFilter = 1000;

            view = await DataCaptureView.forContext(context);
            view.connectToElement(createHostElementIfNeeded());
            view.addControl(new CameraSwitchControl());

            barcodeCapture = await BarcodeCapture.forContext(context, settings);
            await barcodeCapture.setEnabled(false);

            overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
            await overlay.setViewfinder(
                new RectangularViewfinder(RectangularViewfinderStyle.Square, RectangularViewfinderLineStyle.Light),
            );
            await view.addOverlay(overlay);

            camera = Camera.default;
            await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
            await context.setFrameSource(camera);
        },
        async cleanup() {
            await context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
            await context?.dispose();
            await context?.removeAllModes();
            await view?.removeOverlay(overlay);
            barcodeCapture?.removeListener(barcodeCaptureListener);
            view?.detachFromElement();
            host?.remove();
            host = undefined;
        },
        connectToElement(element: HTMLElement) {
            host = createHostElementIfNeeded();
            host.style.display = 'block';
            element.append(host);
        },
        detachFromElement() {
            if (host) {
                host.style.display = 'none';
                document.body.append(host);
            }
        },
        async enableCamera(enabled: boolean) {
            if (context?.frameSource) {
                await context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
            }
        },
        async enableScanning(enabled: boolean) {
            await barcodeCapture?.setEnabled(enabled);
        },
        addBarcodeCaptureListener(listener: BarcodeCaptureListener) {
            barcodeCapture?.addListener(listener);
        },
        removeBarcodeCaptureListener(listener: BarcodeCaptureListener) {
            barcodeCapture?.removeListener(listener);
        },
    };
}

export const SDKContext = createContext({
    loaded: false,
    loading: false,
    sdk: null,
} as unknown as SDKWithLoadingStatus);

export interface SDKProviderProps {
    readonly children: ReactNode;
}

export default function SDKProvider({ children }: SDKProviderProps): JSX.Element {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const sdk = useMemo(() => createSDKFacade(), []);

    const providerValue = useMemo(() => ({ loading, loaded, sdk }), [loading, loaded, sdk]);

    useEffect(() => {
        async function start(): Promise<void> {
            setLoading(true);
            await sdk.initialize();
            setLoading(false);
            setLoaded(true);
        }
        start();
        return () => {
            sdk.cleanup();
        };
    }, [sdk]);

    return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
}

export async function onMount(
    loaded: boolean,
    host: MutableRefObject<HTMLDivElement | null>,
    sdk: SDK,
    onScan: BarcodeCaptureListener,
): Promise<void> {
    if (loaded && host.current) {
        sdk.connectToElement(host.current);
        await sdk.enableCamera(true);
        await sdk.enableScanning(true);
        sdk.addBarcodeCaptureListener(onScan);
    }
}

export function createOnScan(sdk: SDK, setBarcode: (data: string) => void): BarcodeCaptureListener {
    return {
        didScan: (_: BarcodeCapture, session: BarcodeCaptureSession) => {
            const newlyRecognizedBarcode = session.newlyRecognizedBarcode;
            if (newlyRecognizedBarcode) {
                (async () => {
                    await sdk.enableScanning(false);
                    setBarcode(newlyRecognizedBarcode.data ?? '');
                })();
            }
        },
    };
}

export function useSDK(): SDKWithLoadingStatus {
    return useContext(SDKContext);
}

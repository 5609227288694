import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FlagOutlineIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M12.604 4.24688C13.7433 4.24688 14.9803 3.85559 16.1522 3.33386C17.1613 2.90996 18.3332 3.65994 18.3332 4.7686V12.5945C18.3332 13.1488 18.0402 13.6379 17.617 13.9314C16.7381 14.4857 15.3384 15.2031 13.4178 15.2031C11.2043 15.2031 9.73942 14.1596 8.14437 14.1596C6.32145 14.1596 5.21468 14.5509 4.01025 15.1053V17.8117C4.01025 18.1052 3.74984 18.3334 3.48942 18.3334H2.96859C2.67562 18.3334 2.44775 18.1052 2.44775 17.8117V4.57296C1.95947 4.3121 1.6665 3.79037 1.6665 3.20343C1.6665 2.32302 2.4152 1.60565 3.32666 1.67087C4.07536 1.70348 4.69385 2.29042 4.75895 3.04039C4.75895 3.10561 4.75895 3.17083 4.75895 3.20343C4.75895 3.39908 4.75895 3.56212 4.69385 3.72516C5.40999 3.43169 6.2889 3.20343 7.33057 3.20343C9.54411 3.20343 11.009 4.24688 12.604 4.24688ZM16.7707 12.5945V4.7686C15.729 5.25772 14.0037 5.81205 12.604 5.81205C10.6509 5.81205 9.28369 4.7686 7.33057 4.7686C5.96338 4.7686 4.69385 5.32293 4.01025 5.81205V13.377C5.01937 12.9205 6.74463 12.5945 8.14437 12.5945C10.0975 12.5945 11.4647 13.6379 13.4178 13.6379C14.785 13.6379 16.0545 13.1162 16.7707 12.5945Z' />
        </SvgIcon>
    );
}

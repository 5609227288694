import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FlagIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M12.943 4.86595C14.2988 4.86595 15.6877 4.34421 16.812 3.82246C17.5065 3.52898 18.3332 4.01812 18.3332 4.76812V12.7247C18.3332 13.0508 18.1348 13.3769 17.8702 13.5725C16.6797 14.3551 15.39 14.9095 13.8027 14.9095C11.5871 14.9095 10.1321 13.7682 8.3464 13.7682C6.65989 13.7682 5.50248 14.0943 4.57656 14.4856V17.5508C4.57656 18.0073 4.2128 18.3334 3.78291 18.3334H3.25381C2.79084 18.3334 2.46015 18.0073 2.46015 17.5508V4.96377C1.96412 4.63769 1.6665 4.11594 1.6665 3.46377C1.6665 2.45289 2.52629 1.60506 3.58449 1.67028C4.51042 1.70289 5.30407 2.45289 5.33714 3.36594C5.33714 3.39855 5.33714 3.43116 5.33714 3.49637C5.33714 3.72464 5.30407 3.92029 5.23793 4.14855C5.93238 3.88768 6.65989 3.72464 7.48661 3.72464C9.70222 3.72464 11.1572 4.86595 12.943 4.86595Z' />
        </SvgIcon>
    );
}

import { Grid } from '@mui/material';
import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { ISingleColumnContainer, isSingleColumn } from '@features/Filtering/Contracts/ISingleColumnContainer';
import { ITwoColumnsContainer, isTwoColumns } from '@features/Filtering/Contracts/ITwoColumnsContainer';
import { IProps as IFilterItemProps } from '../FilterItem/FilterItem';
import VariableSizeListItemDesktop from './Components/VariableSizeListItemDesktop/VariableSizeListItemDesktop';
import VariableSizeListItemHeader from './Components/VariableSizeListItemHeader/VariableSizeListItemHeader';
import VariableSizeListItemMobile from './Components/VariableSizeListItemMobile/VariableSizeListItemMobile';

interface IProps extends Omit<IFilterItemProps, 'item' | 'selected'> {
    readonly row: ISingleColumnContainer | ITwoColumnsContainer;
    readonly style: React.CSSProperties;
    readonly selectedItems: FilterOption[];
    readonly filterType: SupplyFilterType;
}

export default function VariableSizeListItem(props: IProps) {
    const { row, style, selectedItems, showCount, showFilterOptionCode, onToggle, filterType } = props;

    function isSelected(item?: FilterOption) {
        return !!selectedItems?.find(selectedItem => selectedItem.key === item?.key);
    }

    return (
        <Grid container style={style} data-testid='filterRow'>
            {row.header && <VariableSizeListItemHeader header={row.header.name} />}
            {!row.header && isSingleColumn(row) && row.item && (
                <VariableSizeListItemMobile
                    filterItem={row.item}
                    selected={isSelected(row.item)}
                    showCount={showCount}
                    showFilterOptionCode={showFilterOptionCode}
                    onToggle={onToggle}
                    filterType={filterType}
                />
            )}
            {!row.header && isTwoColumns(row) && (
                <VariableSizeListItemDesktop
                    row={row}
                    leftSelected={isSelected(row.leftItem)}
                    rightSelected={isSelected(row.rightItem)}
                    showCount={showCount}
                    showFilterOptionCode={showFilterOptionCode}
                    onToggle={onToggle}
                    filterType={filterType}
                />
            )}
        </Grid>
    );
}

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FlagGroupIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.0256 3.28353C12.1824 3.67484 11.1407 4.06615 10.1238 4.06615C9.49028 4.06615 8.91222 3.87459 8.30301 3.67271C7.62442 3.44784 6.9072 3.21016 6.03158 3.21016C5.41154 3.21016 4.86591 3.33245 4.34508 3.5281C4.39468 3.3569 4.41948 3.21016 4.41948 3.03897V2.94114C4.39468 2.25635 3.79944 1.69385 3.105 1.66939C2.31135 1.62048 1.6665 2.25635 1.6665 3.01451C1.6665 3.50364 1.88972 3.89495 2.26174 4.13952V13.5798C2.26174 13.9222 2.50976 14.1667 2.85698 14.1667H3.25381C3.57623 14.1667 3.84904 13.9222 3.84904 13.5798V11.2809C4.49714 11.007 5.29645 10.7757 6.42841 10.746V17.7465C6.42841 18.0888 6.67642 18.3334 7.02365 18.3334H7.42047C7.74289 18.3334 8.01571 18.0888 8.01571 17.7465V15.4475C8.71016 15.154 9.57821 14.9095 10.8431 14.9095C11.4767 14.9095 12.0547 15.101 12.6639 15.3029C13.3425 15.5278 14.0597 15.7655 14.9354 15.7655C16.1258 15.7655 17.0931 15.3497 17.9859 14.7627C18.1844 14.616 18.3332 14.3714 18.3332 14.1269V8.15944C18.3332 7.59694 17.7131 7.23009 17.1923 7.4502C16.349 7.84151 15.3074 8.23281 14.2905 8.23281C14.2489 8.23281 14.2076 8.23199 14.1665 8.23039V3.99278C14.1665 3.43027 13.5465 3.06342 13.0256 3.28353Z'
            />
        </SvgIcon>
    );
}

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function ClockSupplyLogoIconWithoutText(props: SvgIconProps) {
    return (
        <SvgIcon viewBox='0 0 1024 1150' {...props}>
            <path
                fill='#fff'
                d='M1024,93.95v962.09c0,51.96-42.06,93.95-93.89,93.95H93.89c-51.92,0-93.89-42-93.89-93.95V93.95C0,42,41.97,0,93.89,0h836.21c51.83,0,93.89,42,93.89,93.95ZM444.18,494.71l-67.87-67.8-159.45,159.56-91.7-91.76-67.75,67.8,159.45,159.56,227.33-227.35ZM501.58,188.24v105.23h397.07v-105.23h-397.07ZM501.58,521.87v105.23h397.07v-105.23h-397.07ZM501.58,855.5v105.23h397.07v-105.23h-397.07ZM363.46,353.64v-225.48h-225.33v225.48h225.33ZM288.34,203.32h-75.1v75.15h75.1v-75.15ZM363.46,1020.81v-225.48h-225.33v225.48h225.33ZM288.34,870.49h-75.1v75.15h75.1v-75.15Z'
            />
        </SvgIcon>
    );
}

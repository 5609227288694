import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    accountsContainer: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
        },
        maxHeight: '200px',
        overflow: 'auto',
    },
    accountsItem: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        flexWrap: 'nowrap',
    },
    groupItem: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexWrap: 'nowrap',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    accountsItemLabel: {
        paddingLeft: theme.spacing(0.5),
    },
    saveGroup: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    saveGroupLink: {
        fontWeight: 'normal',
    },
}));

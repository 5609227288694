import { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { namespaces } from '@root/i18n';
import {
    addMarkingUser,
    addMarkingUsers,
    deleteMarkingUser,
    deleteMarkingUsers,
} from '@store/Actions/markingUsersActions';
import { useMarkingUsersContext } from '@store/Providers/MarkingUsersProvider';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';
import { useStyles } from './AccountsMarkingListStyles';

interface IProps {
    readonly accounts: string[];
    readonly onChange: (selected: boolean, user: string) => void;
    readonly supplyLineId: string;
}

export default function AccountsMarkingList(props: IProps) {
    const { accounts, onChange, supplyLineId } = props;
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'markButton' });

    const [markingsGroup, setMarkingsGroup] = useState<string[]>(loadFromLocalStorage('markings-group') ?? []);
    const {
        state: { markings: markingsInContext },
        dispatch,
    } = useMarkingUsersContext();
    const supplyLineMarkings = markingsInContext[supplyLineId] ?? [];
    const allUsersInGroupMarked = markingsGroup.every(user => supplyLineMarkings.some(userName => userName === user));

    function saveGroup() {
        localStorage.setItem('markings-group', JSON.stringify(supplyLineMarkings));
        setMarkingsGroup(supplyLineMarkings);
    }

    return (
        <>
            {markingsGroup.length > 0 && (
                <Box className={classes.groupItem} data-testid='markingGroup'>
                    <MarkButtonContainer
                        onChange={selected => {
                            if (selected) dispatch(addMarkingUsers(supplyLineId, markingsGroup));
                            else dispatch(deleteMarkingUsers(supplyLineId, markingsGroup));

                            markingsGroup.forEach(user => {
                                onChange(selected, user);
                            });
                        }}
                        selected={allUsersInGroupMarked}
                        group
                    />
                    <Typography className={classes.accountsItemLabel}>
                        {t('myGroup', {
                            count: markingsGroup.length,
                        })}
                    </Typography>
                </Box>
            )}
            <Box className={classes.accountsContainer}>
                {accounts.map(user => (
                    <Box key={user} className={classes.accountsItem} data-testid='markingAccount'>
                        <MarkButtonContainer
                            onChange={selected => {
                                if (selected) dispatch(addMarkingUser(supplyLineId, user));
                                else dispatch(deleteMarkingUser(supplyLineId, user));
                                onChange(selected, user);
                            }}
                            selected={!!supplyLineMarkings?.includes(user)}
                        />
                        <Typography className={classes.accountsItemLabel}>{user}</Typography>
                    </Box>
                ))}
            </Box>
            {supplyLineMarkings.length > 0 &&
                !(supplyLineMarkings.length === markingsGroup.length && allUsersInGroupMarked) && (
                    <Box className={classes.saveGroup}>
                        <Link
                            className={classes.saveGroupLink}
                            component='button'
                            underline='always'
                            onClick={saveGroup}
                            data-testid='saveGroup'
                        >
                            {markingsGroup.length === 0 ? t('saveAsGroup') : t('replaceGroup')}
                        </Link>
                    </Box>
                )}
        </>
    );
}
